<template>
  <Field
    v-if="fieldContext && fieldDefinition"
    path="dummy"
    :definition="fieldDefinition"
    :context="fieldContext"
  />
</template>
<script>
import { clone, uniqueID } from "@/util";
export default {
  name: "FieldPreview",
  props: {
    definition: {
      type: Object,
    },
  },
  watch: {
    definition() {
      this.fieldDefinition = this.definition && clone(this.definition);
    },
  },
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  data() {
    const fieldDefinition = this.definition && clone(this.definition);
    return {
      fieldContext: "",
      fieldDefinition,
    };
  },
  mounted() {
    this.createContext();
  },
  methods: {
    async createContext() {
      const component = this;
      let { store } = await import("@/components/fields/store");
      const fieldContext = `field_preview_${
        this.definition?.name || uniqueID()
      }`;
      component._createStore(fieldContext, store);
      component.fieldContext = fieldContext;
    },
  },
};
</script>
